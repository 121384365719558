.goaContent {
  color: #fff;
  text-align: center;
  justify-content: center;
  font-family: Butler;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.56px;
}
/* .swiper-pagination-bullet-active {
  background-color: white !important; 
  font-weight: bold;
}
.swiper-pagination {
  margin-bottom: 80px;
} */

.swiper-pagination-bullet {
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.8;
  margin: 0 2px;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  /* background-color: black; */
  font-weight: bold;
}
