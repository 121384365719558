/* CuponCard.css */
.swiper-pagination-bullet {
  background: #000; /* Change to your desired color */
}

.swiper-pagination-bullet-active {
  background: #ff9900; /* Change to your desired color */
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #176cda;
}
