@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;

@layer utilities {
  html {
    scroll-behavior: smooth;
  }
}
@tailwind utilities;
@layer base {
  @font-face {
    font-family: Butler;
    src: url("../src/assets/Fonts/Butler_Regular.otf");
  }
}

.loader {
    border: 3px solid transparent;
    border-top: 3px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .hide_scrollbar::-webkit-scrollbar {
    display: none;
  }