/* Custom styles to hide the flag and show the country code */
.custom-phone-input-container .flag-dropdown {
  display: flex;
  align-items: center;
  border-right: 2px solid black;
}

.custom-phone-input-container .selected-flag .country-code {
  display: block;
  font-weight: bold;
  padding-right: 5px;
}
.phone-input-wrapper input::placeholder {
  color: #9ca3af; /* Tailwind gray-400 */
  opacity: 1; /* Ensure full opacity */
}

.custom-phone-input-container .selected-flag .flag {
  display: none;
}

/* Custom styles for the dropdown */
.custom-dropdown .country-list {
  position: absolute;
  max-height: 300px;
  overflow-y: scroll;
}

.custom-dropdown .country {
  padding: 5px 10px;
  cursor: pointer;
}

.custom-dropdown .country:hover {
  background-color: black;
  color: black;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: transparent;
}
.custom-dropdown .country .country-name {
  margin-left: 10px;
}

.custom-dropdown .country .country-code {
  font-weight: bold;
  margin-left: 5px;
  border-left: 2px solid black;
}
ul.country-list.custom-dropdown {
  position: absolute;
  top: 37px;
}
.react-tel-input .form-control {
  margin-left: 5px;
  padding-left: 5px;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  /* width: 38px; */
  height: 100%;
  /* padding: 0 0 0 8px; */
  border-radius: 3px 0 0 3px;
}
.react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
    /* border-radius: 3px 0 0 0; */
}
.react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
    /* border-radius: 3px 0 0 0; */
}
.custom-dropdown .iti__flag-container {
    display: none; /* Hide the flag icon */
  }
  
  .custom-dropdown .iti__selected-flag {
    display: none; /* Hide the selected flag */
  }
  
  .custom-dropdown .iti__country-name {
    margin-left: 0; /* Adjust country name alignment */
  }
  
  .custom-dropdown .iti__country-list {
    width: auto; /* Adjust the width of the country list */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow for dropdown */
  }

  .custom-dropdown .iti__flag-container,
.custom-dropdown .iti__selected-flag {
  display: none; /* Hide both flag container and selected flag */
}

.custom-dropdown .iti__country-name {
  margin-left: 0; /* Adjust country name alignment */
}

.custom-dropdown .iti__country-list {
  width: auto; /* Adjust the width of the country list */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow for dropdown */
}
.react-tel-input .country-list .flag {
    display: none;
}
.react-tel-input .country-list .country{
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-between;
}
