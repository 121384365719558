.data {
  /* border-radius: 4px; */
  /* background: #fff; */
  /* height: auto; */
  /* box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12); */
  @apply lg:bg-[#FFF] h-auto lg:rounded-[4px] lg:shadow-[0px_4px_24px_0px_rgba(0,0,0,0.12)];
}

/* .react-datepicker__month-container {
  @apply w-[110%] !important;
} */
