.App{
    background-color: white;
}
.orLine{
    height: 1px;
    width: 250px;
}

.slider{
    width: 100%;
    height: 5px;
    background-color: #08090A;
    position: relative;
}

.slider .thumb{
    width: 23px;
    height: 23px;
    cursor: pointer;
    position: absolute;
    top: -9px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #434141;
}


/* Loader animation */
.loader {
    border: 3px solid transparent;
    border-top: 3px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  