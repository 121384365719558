/* width: Fixed (196px)px;
height: Hug (90px)px;
gap: 16px;
opacity: 0px; */
.gridWrap {
  display: grid;
  /* grid-gap: 10px; */
  grid-template-columns: repeat(auto-fit, 196px);
}
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
