/* Width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 40px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */
.custom-scroll{
    overflow-y: scroll;
    overflow-x: hidden;
   
}
.custom-scroll::-webkit-scrollbar {
    width: 9px; /* Width of the scrollbar */
}

.custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the track */
    border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color on hover */
}