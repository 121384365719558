.rdrDefinedRangesWrapper {
  display: none !important;
}
.rdrDateDisplayWrapper {
  display: none !important;
}
.rdrMonth {
  @apply relative  !important;
}
.rdrMonthName {
  @apply absolute font-bold text-base text-[#232020] -left-0 -top-14  w-full flex justify-center z-0 !important;
}
.rdrMonthAndYearWrapper {
  @apply pt-0 !important;
}
.rdrNextPrevButton {
  @apply bg-transparent z-30 scale-150 !important;
}
.rdrMonthAndYearPickers select {
  @apply hidden !important;
}
.rdrDayNumber {
  @apply text-[#232020] font-semibold !important;
}
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: transparent !important;
}
.rdrDayToday .rdrDayNumber span:after {
  @apply bg-[#232020] !important;
}
.rdrStartEdge {
  @apply text-white  bg-[#232020] !important;
}
.rdrEndEdge {
  @apply text-white  bg-[#232020] !important;
}
.rdrDayNumber span:after {
  @apply text-white !important;
}
.rdrDayHovered {
  @apply border-[#232020] !important;
}

.rdrInRange {
  @apply bg-[#F1F1F1] !important;
}

.rdrSelected ~ .rdrDayNumber span {
  @apply text-white !important;
}
.rdrDay {
  @apply h-12 w-12 !important;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  @apply text-[#232020] !important;
}
.rdrMonth {
  @apply p-0 w-[340px] !important;
}
.rdrMonths {
  @apply max-md:flex-col  max-lg:gap-y-12 md:gap-x-4 lg:gap-x-[32px] !important;
}
.rdrWeekDay {
  @apply text-zinc-500 h-12 w-12 flex justify-center items-center text-base font-semibold !important;
}
.rdrDayToday {
  @apply rounded-md !important;
}
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.rdrCalendarWrapper {
  @apply bg-transparent !important;
}
.rdrDayDisabled {
  @apply md:bg-[#f8f8f8] bg-transparent !important;
}
