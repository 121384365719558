
    .swiper-pagination {
        top: 80% !important;
      }
      
      .swiper-pagination-bullet {
        width: 10px !important;
        height: 10px !important;
        background-color: rgba(255, 255, 255, 0.5) !important;
        margin: 0 5px !important;
        border-radius: 10px !important;
      }
      
      .swiper-pagination-bullet-active {
        background-color: #ffffff !important;
        font-weight: bold !important;

      }